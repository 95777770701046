import { useState, useEffect, useRef } from "react";
import "./home.css";
import { FaCheckCircle } from "react-icons/fa";
import axios from 'axios';
import graphicDesign from '../images/homeImages/graphic-design.svg'
import global from '../images/homeImages/global.svg'
import network from '../images/homeImages/global-network.svg'
import dollar from '../images/homeImages/dollar.svg'
import developer from '../images/homeImages/developer.svg'
import chat from '../images/homeImages/chat.svg'
// import Banner from "../images/Banner-bg.png";
// import Banner from '../images/bgbanner.png'
// import BannerMob from "../images/homeImages/Banner.png";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import CssSwiper from "../components/Common/CssSwiper";
import Technologies from "../components/Common/Technologies";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Modal } from "react-bootstrap";
import { LiaEdit } from "react-icons/lia";
import { IoMdClose } from "react-icons/io";
import { toast } from "react-toastify";
// import Testimonials from "../components/Common/Testimonials";
// import Animation from "../components/Common/Animation";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import { FaArrowRightLong } from "react-icons/fa6";
import Stats from "../components/Common/Stats";
import { IoIosArrowRoundForward } from "react-icons/io";
import whoweimg from '../images/whoweareimg.png'
import imgcontactmob from "../images/contactmobside.png";
import img1 from '../images/aatmanlogo1.png';
import img2 from '../images/yahalogo1.png';
import img3 from '../images/am.png';
import img4 from '../images/g.png';
import img5 from '../images/o.png';
import img6 from '../images/p2slogo.png';
import img7 from '../images/izelogo1.png';

import imgcontact from '../images/contactsideimg.png'

// import a from "../images/techImages/googleapp.png"
// import b from "../images/techImages/googleseo.png"
import { CiLocationOn } from "react-icons/ci";
import { BsTelephone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import Contactform from "../components/Common/Contactform";
// import imgweb from '../images/img1.png'
// import imgweb2 from '../images/img2.png'
import Testmonials from "../components/Common/Testmonials";
import initializeGTag from "../Analytics";


export default function Home() {
  let changeImg = window.innerWidth;

  const sectionRef = useRef(null);
  const [country, setCountry] = useState("");

  const apiKey = "AIzaSyDC97e4cayS1sR0iq8OREzSIMQHjeN6eUI";

  const location = useLocation();
  console.log(location.pathname);
  const path = location.pathname;

  // useEffect(() => {
  //   if ("geolocation" in navigator) {
  //     navigator.geolocation.getCurrentPosition(
  //       async (position) => {
  //         try {
  //           const { latitude, longitude } = position.coords;

  //           const response = await axios.get(
  //             `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
  //           );

  //           //  const country = response.data.results[0].address_components.find(
  //           //   (component) => component.short_name
  //           // );

  //           const addressComponents =
  //             response.data.results[0].address_components;

  //           const countryComponent = addressComponents.find((component) =>
  //             component.types.includes("country")
  //           );

  //           if (countryComponent) {
  //             const countryShortName = countryComponent.short_name;

  //             setCountry(countryShortName);
  //           } else {
  //             console.log("Country component not found");
  //           }
  //         } catch (error) {
  //           console.error("Failed to fetch data:", error);
  //         }
  //       },
  //       (error) => {
  //         console.error("Error getting user location:", error.message);
  //       }
  //     );
  //   } else {
  //     console.error("Geolocation is not supported by your browser.");
  //   }
  // }, []);

  useEffect(() => {
    if (window.location.hash) {
      const id = window.location.hash.substring(1);
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);
  
   useEffect(() => {
     initializeGTag("G-M3J553CE2J"); // Replace with your home page Measurement ID
   }, []);

  const [modal, setModal] = useState(false);

  const handleContactClick = () => {
    setModal(true);
  };

  // navigation
  const navigate = useNavigate();

  // form
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    contactNumber: "",
    // message: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  const handlePhoneChange = (value) => {
    const maxDigits = 13;

    if (value?.replace(/\D/g, "").length > maxDigits) {
      setErrors({
        ...errors,
        contactNumber: `Contact number cannot exceed ${10} digits.`,
      });
    } else {
      setErrors({ ...errors, contactNumber: "" });
    }
    setFormData({ ...formData, contactNumber: value });
  };

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!formData.fullName?.trim()) {
      errors.fullName = "Full Name is required";
      isValid = false;
    }

    if (!formData.email?.trim()) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is invalid";
      isValid = false;
    }

    if (!formData.contactNumber?.trim()) {
      errors.contactNumber = "Contact Number is required";
      isValid = false;
    } else if (!/^\+\d{1,4}\d{10}$/.test(formData.contactNumber)) {
      errors.contactNumber = "Contact Number is invalid";
      isValid = false;
    }

    return { isValid, errors };
  };

  const handleThankuPage = () => {
    navigate("/thank-you");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { isValid, errors } = validateForm();

    if (isValid) {
      const data = {
        fullName: formData.fullName,
        email: formData.email,
        contactNumber: formData.contactNumber,
        message: formData.message || "No message provided.",
      };

      try {
        const response = await axios.post(
          `https://server-dot-apenterpirse-test.el.r.appspot.com/sendcontactmail`,
          data
        );

        if (response.status === 200) {
          toast.success(
            "Thank You for reaching out to us. We'll get back to you shortly."
          );
          console.log("responseapi", response);
          window.scrollTo(0, 0);
          setFormData({
            fullName: "",
            email: "",
            contactNumber: "",
            message: "",
            captcha: "",
          });
          // setInputText("");
          // setCaptchaValid("");
          // setErrors({ ...errors, captcha: "" });
          // handleThankuPage();
          navigate("/thank-you");
        } else {
          toast.error("Oops! Something went wrong.");
        }
      } catch (err) {
        // toast.error("Oops! Something went wrong.");
        console.error(err);
      }
    } else {
      setErrors(errors);
      console.log("Form validation failed");
    }
  };

  const handleCloseModal = () => {
    setModal(false);
    setFormData({
      fullName: "",
      email: "",
      contactNumber: "",
    });
  };
  // for modal showing

  useEffect(() => {
    setModal(false);
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); 

   const contactSectionRef = useRef(null);

   const handleNavigate = () => {
    contactSectionRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);


  return (
    <>
      <Header handleNavigate={handleNavigate} />
      {/* Banner  */}

      <div className="bannerrole d-md-block d-none">
        <div className="d-flex flex-wrap align-items-center bannerbackground">
          <div className="col-md-7  ">
            <div class="banner-box1">
              <h4 class="typewriter heading-effect mx-1">
                <span
                  style={{ fontWeight: "400", fontSize: "24px" }}
                  className="text-white"
                >
                  From Concept to Code
                </span>
              </h4>
              <h1 class="typewriter subheading-effect mx-">
                <span
                  style={{
                    fontSize: "48px",
                    fontWeight: "600",
                    lineHeight: "56px",
                  }}
                  className="text-white fontforimacbannerhead "
                >
                  Crafting Web Applications <br />
                  That Shape The Future
                  <br />
                </span>

                <h4 class="typewriter heading-effect mx-2">
                  <span
                    style={{ fontWeight: "400", fontSize: "20px" }}
                    className="text-white"
                  >
                    Customized, Scalable, and Secure
                  </span>
                </h4>
              </h1>
            </div>

            <div className="d-flex justify-content-evenly mt-md-5 mx-md-5 p-3 pt-3 mx-lg-4">
              <div className="col-3 text-white mx-2">
                <h4>10+ Industries</h4>
                <p>Covered across the globe</p>
              </div>
              <div className="col-3 text-white">
                <h4>95%</h4>
                <p>Customer Satisfaction Rate</p>
              </div>
              <div className="col-4 text-white">
                <h4>100%</h4>
                <p>
                  Scalability Guaranteed, ensuring complete future-readiness.
                </p>
              </div>
            </div>

            <div className="">
              <div className=" clientimgdiv p-2 mt-md-3 col-3 pt-3 mx-lg-1 ">
                <div className="d-flex typewriter-images">
                  <span>
                    <img src={img7} className="img-fluid " alt="img" />
                  </span>
                  <span>
                    <img src={img6} className="img-fluid" alt="img" />
                  </span>
                  <span>
                    <img src={img5} className="img-fluid" alt="img" />
                  </span>
                  <span>
                    <img src={img4} className="img-fluid" alt="img" />
                  </span>
                  <span>
                    <img src={img3} className="img-fluid" alt="img" />
                  </span>
                  <span>
                    <img src={img2} className="img-fluid" alt="img" />
                  </span>
                  <span>
                    <img src={img1} className="img-fluid" alt="img" />
                  </span>
                </div>
                <p className="mt-3 mx-2  text-white">
                  {/* <b>10k+</b> Satisfied Client */}
                  Trusted by Industry specialists <br />
                  Across 10+ Sectors
                </p>
              </div>
              <div
                className="btn  col-md-2  mt-md-4 rounded-5 abobutton p-2 forgetinbut btnposition"
                onClick={handleNavigate}
              >
                Start Your Project
                <IoIosArrowRoundForward size={30} className="" />
              </div>
            </div>
          </div>

          <div className="col-md-5 px-md-4 ">
            {/* <img
              src={require("../images/bannerside.png")}
              className="img-fluid  p-md-4 pb-md-0"
            /> */}
          </div>
        </div>
      </div>
      {windowWidth < 480 && (
        <>
          <div className="d-md-none d-block ">
            <div className="bannermobbackground">
              {/* fffff */}
              <div className="bannerboxformob">
                <h4 class="mx-1">
                  <span
                    style={{ fontWeight: "300", fontSize: "px" }}
                    className="text-white textformobh4 "
                  >
                    From Concept to Code
                  </span>
                </h4>
                <h1 className="mt-3 ">
                  <span
                    style={{
                      fontWeight: "500",
                      fontSize: "28px",
                      lineHeight: "1.5",
                    }}
                    className="text-white textformobh1 m  "
                  >
                    {" "}
                    Crafting Web
                    <br /> Applications That Shape
                    <br /> The Future
                  </span>
                </h1>
                <h5 className=" ">
                  <span
                    style={{ fontWeight: "300", fontSize: "18px" }}
                    className="text-white textformobh4 "
                  >
                    Customized, Scalable, and Secure
                  </span>
                </h5>
              </div>

              {/* <div className="d-flex p-1 mt-4 staformob  ">
                <div className="col-5 text-white mx-3 me-1">
                  <h6>10+ Industries</h6>
                  <p
                    className="p-0 m-0  fontformobp"
                    // style={{ lineHeight: "1" }}
                  >
                    Covered across the<br/> globe
                  </p>
                </div>
                <div className=" text-white ">
                  <h6>95%</h6>
                  <p
                    className="p-0 m-0 fontformobp m"
                    // style={{ lineHeight: "1" }}
                  >
                    Customer Satisfaction <br/>Rate
                  </p>
                </div>
                <div className="col-3 text-white ">
                  <h6>100%</h6>
                  <p
                    className="p-0 m-0 fontformobp"
                    // style={{ lineHeight: "1" }}
                  >
                    Scalability Guaranteed, ensuring future-readiness.
                  </p>
                </div>
              </div> */}

              <div className="">
                <div className=" clientimgdiv p-2  col-9 pt-5  ">
                  <div className=" typewriter-images">
                    <span>
                      <img src={img7} className="img-fluid " alt="img" />
                    </span>
                    <span>
                      <img src={img6} className="img-fluid" alt="img" />
                    </span>
                    <span>
                      <img src={img5} className="img-fluid" alt="img" />
                    </span>
                    <span>
                      <img src={img4} className="img-fluid" alt="img" />
                    </span>
                    <span>
                      <img src={img3} className="img-fluid" alt="img" />
                    </span>
                    <span>
                      <img src={img2} className="img-fluid" alt="img" />
                    </span>
                    <span>
                      <img src={img1} className="img-fluid" alt="img" />
                    </span>
                  </div>
                  <p className="mt-3 mx-1  text-white col-12">
                    {/* <b>10k+</b> Satisfied Client */}
                    Trusted by Industry specialists Across 10+ Sectors
                  </p>

                  <div
                    className="btn   mt-4 rounded-5 btnbannermob w-100 "
                    onClick={handleNavigate}
                  >
                    Start Your Project
                    <IoIosArrowRoundForward size={30} className="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* section second */}
      <div>
        <h2 className="text-center mt-5" style={{ fontSize: "42px" }}>
          Explore Our Expertise In <br />
          <span style={{ color: "#288ED7" }}>Web Development</span>
        </h2>
        <p className="text-center mt-3 text-muted d-md-block d-none">
          Your IT journey starts here: uncover expert solutions crafted to
          elevate your business growth.
          <br /> experience customized services for your success story.
        </p>
        <p className="text-center mt-3 text-muted d-md-none  px-1">
          Your IT journey starts here: uncover expert
          <br /> solutions crafted to elevate your business growth.
          <br /> experience customized services for your success story.
        </p>

        <Technologies />
      </div>

      {/* clientspotlight */}
      <div className="text-center my-md-5 mt-md-0 mt-5 swiperforimac">
        <h2 className="mt-md-0 mt-2" style={{ fontSize: "42px" }}>
          Client Spotlights
        </h2>
        <p className="p-md-0 p-3 text-muted">
          Showcasing our exceptional partnerships, inspiring growth and
          innovation.
        </p>
      </div>
      <CssSwiper />
      {/* <div className="d-flex justify-content-center ">
          <div
            className=" btngetfree rounded-2 col-md-3 col-7 text-center p-2 sentmsgbtn mt-md-2 mt-3  "
            style={{ cursor: "pointer" }}
            onClick={handleNavigate}
          >
            Let's discuss your idea
          </div>
        </div> */}

      {/* stats */}
      <Stats />

      {windowWidth < 480 && (
        <>
          <div className="bgforreadymob d-md-none p-4 ">
            <div>
              <h2
                className="text-center mt-5 "
                style={{ fontWeight: "700", fontSize: "32px" }}
              >
                Ready to launch your
                <br /> next project?
              </h2>
              <p
                className="text-center text-muted mt-3"
                style={{ fontSize: "20px", fontWeight: "400" }}
              >
                We respect your privacy. No spam, ever!
                <br /> We help you get started with your idea.
                <br />
                Click on the button to explore what’s in for you
              </p>
            </div>
            <div
              className="text-center  col-10  mx-auto  mt-3 btngetfree rounded-2 text-center   px-3 p-2  sentmsgbtn "
              onClick={handleNavigate}
            >
              Get Started
            </div>
          </div>
        </>
      )}

      {/* sucessstoriees */}
      <div className="container" id="case-studies">
        <h2 className="text-center mt-5" style={{ fontSize: "42px" }}>
          Case Studies
        </h2>
        <p className="text-center my-3 ">
          Learn how our tailored IT services streamline operations and boost
          productivity across various industries.
        </p>
        {/* <Testimonials /> */}
        <Testmonials />
      </div>

      {/* inovation */}
      <div className="inovation-driven p-3 mt-md-5 mt-3">
        <div className="container">
          <h2
            className="text-center mt-md-0 mt-3 d-md-block d-none"
            style={{ fontSize: "42px" }}
          >
            Innovation Driven By New <br />
            <span style={{ color: " #14A4D2 " }}>Technologies</span>
          </h2>
          <h2
            className="text-center mt-md-0 mt-3 d-md-none"
            style={{ fontSize: "42px" }}
          >
            Innovation Driven <br />
            By New <br />
            <span style={{ color: " #14A4D2 " }}>Technologies</span>
          </h2>
          <p className="text-center text-muted pt-3 d-md-block d-none ">
            Effortlessly manage your cloud and on-premises systems with our
            comprehensive support for infrastructure <br />
            and applications.
          </p>
          <p className="text-center text-muted pt-3 d-md-none ">
            Effortlessly manage your cloud and on-premises systems with our
            comprehensive support for infrastructure and applications.
          </p>
        </div>
      </div>
      <div
        style={{ backgroundColor: "#FFF9F3" }}
        className="container rounded-4 p-md-5 mb-5 p-3"
      >
        <div className="d-flex flex-wrap justify-content-center align-items-center pt-4">
          <div className="col-md-9 col-12">
            <div className="imgs d-flex flex-wrap justify-content overflow-auto">
              <div className="col-4 col-md-2 pb-3 pb-md-0 text-center pe-2">
                <img
                  src={require("../images/solutionPageImages/techUsed/react.png")}
                  alt="Img"
                  width="100%"
                  className="img-fluid "
                />
                <p className="secondary-light-txt ">React JS</p>
              </div>
              <div className="col-4 col-md-2 pb-3 pb-md-0 text-center pe-2">
                <img
                  src={require("../images/solutionPageImages/techUsed/angular.png")}
                  alt="Img"
                  width="100%"
                  className="img-fluid "
                />
                <p className="secondary-light-txt ">Angular</p>
              </div>
              <div className="col-4 col-md-2 pb-3 pb-md-0 text-center pe-2">
                <img
                  src={require("../images/solutionPageImages/techUsed/javascript.png")}
                  alt="Img"
                  width="100%"
                  className="img-fluid "
                />
                <p className="secondary-light-txt ">Javascript</p>
              </div>

              <div className="col-4 col-md-2 pb-3 pb-md-0 text-center pe-2">
                <img
                  src={require("../images/solutionPageImages/techUsed/html.png")}
                  alt="Img"
                  width="100%"
                  className="img-fluid "
                />
                <p className="secondary-light-txt ">HTML5</p>
              </div>
              <div className="col-4 col-md-2 pb-3 pb-md-0 text-center pe-2">
                <img
                  src={require("../images/solutionPageImages/techUsed/css.png")}
                  alt="Img"
                  width="100%"
                  className="img-fluid "
                />
                <p className="secondary-light-txt ">CSS</p>
              </div>

              <div className="col-4 col-md-2 pb-3 pb-md-0 text-center pe-2">
                <img
                  src={require("../images/solutionPageImages/techUsed/bootstrap.png")}
                  alt="Img"
                  width="100%"
                  className="img-fluid "
                />
                <p className="secondary-light-txt ">Bootstrap 5</p>
              </div>
              <div className="col-4 col-md-2 text-center pe-2">
                <img
                  src={require("../images/solutionPageImages/techUsed/figma.png")}
                  alt="Img"
                  width="100%"
                  className="img-fluid "
                />
                <p className="secondary-light-txt ">Figma</p>
              </div>
              <div className="col-4 col-md-2 pb-3 pb-md-0 text-center pe-2">
                <img
                  src={require("../images/solutionPageImages/techUsed/Wordpresstech.png")}
                  alt="Img"
                  width="100%"
                  className="img-fluid "
                />
                <p className="secondary-light-txt ">WordPress</p>
              </div>
              <div className="col-4 col-md-2 pb-3 pb-md-0 text-center pe-2">
                <img
                  src={require("../images/solutionPageImages/techUsed/shopify.png")}
                  alt="Img"
                  width="100%"
                  className="img-fluid "
                />
                <p className="secondary-light-txt ">Shopify</p>
              </div>

              <div className="col-4 col-md-2 pb-3 pb-md-0 text-center pe-2">
                <img
                  src={require("../images/solutionPageImages/techUsed/android.png")}
                  alt="Img"
                  width="100%"
                  className="img-fluid "
                />
                <p className="secondary-light-txt ">Android</p>
              </div>
              <div className="col-4 col-md-2 pb-3 pb-md-0 text-center pe-2">
                <img
                  src={require("../images/solutionPageImages/techUsed/flutter.png")}
                  alt="Img"
                  width="100%"
                  className="img-fluid "
                />
                <p className="secondary-light-txt ">Flutter</p>
              </div>
              <div className="col-4 col-md-2 pb-3 pb-md-0 text-center pe-2">
                <img
                  src={require("../images/solutionPageImages/techUsed/ios.png")}
                  alt="Img"
                  width="100%"
                  className="img-fluid "
                />
                <p className="secondary-light-txt ">IOS</p>
              </div>
              <div className="col-4 col-md-2 pb-3 pb-md-0 text-center pe-2">
                <img
                  src={require("../images/solutionPageImages/techUsed/python.png")}
                  alt="Img"
                  width="100%"
                  className="img-fluid "
                />
                <p className="secondary-light-txt ">Python</p>
              </div>
              <div className="col-4 col-md-2 pb-3 pb-md-0 text-center pe-2">
                <img
                  src={require("../images/solutionPageImages/techUsed/googlecloud.png")}
                  alt="Img"
                  width="100%"
                  className="img-fluid "
                />
                <p className="secondary-light-txt ">Google Cloud</p>
              </div>
              <div className="col-4 col-md-2 pb-3 pb-md-0 text-center pe-2">
                <img
                  src={require("../images/solutionPageImages/techUsed/aws.png")}
                  alt="Img"
                  width="100%"
                  className="img-fluid "
                />
                <p className="secondary-light-txt ">AWS</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* contactsection */}

      <div
        ref={contactSectionRef}
        className="backgroundforcontact d-md-block d-none"
      >
        <div className="d-flex  justify-content-between container">
          <div className="col-md-6  mt-5">
            <img alt="img" className="img-fluid " src={imgcontact} />
          </div>
          <div className="col-md-6 mt-3 mx-5 ">
            <Contactform />
          </div>
        </div>
      </div>

      {/* who we are */}
      <div className="container">
        <div className="d-flex flex-wrap justify-content-evenly  px-md-2 px-3 mt-5 pb-4 ">
          <div className="col-lg-6 col-12">
            <img src={whoweimg} className="img-fluid " alt="img" />
          </div>
          <div className="col-lg-5 col-12">
            <h2 style={{ fontSize: "42px" }} className="mt-md-0 mt-3">
              Who We Are?
            </h2>
            <p className="d-md-block d-none">
              At Datart Infotech, we’re not just tech experts—we’re your digital
              dream team. Driven by innovation and excellence, we build custom
              web solutions, from stunning websites and mobile apps to bespoke
              software, all tailored to your unique vision. With every project,
              we aim to deliver results that align with your goals, backed by a
              client-first approach.
            </p>
            <p className="d-md-none">
              At Datart Infotech, we’re not just tech experts—we’re your digital
              dream team. Driven by innovation and excellence, we build custom
              web solutions, from stunning websites and mobile apps to bespoke
              software, all tailored to your unique vision. With every project,
              we aim to deliver results that align with your goals, backed by a
              client-first approach.
            </p>
            <h6>
              Together, let’s turn challenges into opportunities and ideas into
              impactful realities.
            </h6>
            <div
              className="col-md-6 btngetfree rounded-2 p-2 text-center mt-4 sentmsgbtn "
              style={{ cursor: "pointer" }}
              onClick={handleNavigate}
            >
              Get a FREE expert advice
            </div>
          </div>
        </div>
      </div>

      {/* why choose us  */}
      {/* <div className="choose-us-bg">
        <div className="container mb-lg-5">
          <div className="d-flex flex-wrap justify-content-evenly align-items-center mt-4 px-md-0 px-3">
            <div className="col-lg-5 col-12">
              <h2
                className="text-start mb-md-2 mb-3 mt-md-0 mt-3"
                style={{ fontSize: "42px" }}
              >
                Why Choose Us?
              </h2>
              <p className="text-start d-none d-md-block">
                Elevate your business with our unmatched IT solutions, crafted
                to empower your growth, streamline operations, enhance
                efficiency, to achieve remarkable success. We provide
                personalised support and guidance tailored to your unique need.
              </p>
            </div>
            <div className="col-lg-5 col-12 mb-md-0 mb-3 ">
              <img
                loading="lazy"
                src={require("../images/4.png")}
                alt=""
                className="img-fluid w-100 rounded-2"
              />
            </div>
            <p className="d-md-none d-block">
              Elevate your business with our unmatched IT solutions, crafted to
              empower your growth, streamline operations, enhance efficiency ,to
              achieve remarkable success. We provide personalised support and
              guidance tailored to your unique need.
            </p>
          </div>
        </div>

        <div className="inside-data mb-lg-5 mx-auto mt-md-0 mt-4 px-3 px-md-0">
          <div className="container d-flex flex-wrap justify-content-center align-items-center ">
            <div className="col-md-4 approches px-md-5 pe-md-0 ">
              <img src={graphicDesign} alt="" className="img-fluid" />
              <h4 className="mb-3 pt-3">Customer-Centric Approach</h4>
              <p className="secondary-light-color">
                Prioritizing your needs and goals to deliver tailored solutions.
              </p>
            </div>
            <div className="col-md-4 approches px-md-5 pe-md-0">
              <img src={network} alt="" className="img-fluid" />
              <h4 className="mb-3 pt-3">Global Expertise</h4>
              <p className="secondary-light-color">
                Leveraging international experience to bring innovative
                perspectives.
              </p>
            </div>
            <div className="col-md-4 approches px-md-5 pe-md-0 mx-md-0">
              <img src={global} alt="" className="img-fluid" />
              <h4 className="mb-3 pt-3">Focus on Results</h4>
              <p className="secondary-light-color">
                Committed to achieving measurable success for your projects.
              </p>
            </div>
          </div>
        </div>

        <div className="inside-data px-3 px-md-0">
          <div className="container d-flex flex-wrap justify-content-center align-items-center ">
            <div className="col-md-4 approches px-md-5 pe-md-0 me-md-0 me-5">
              <img src={dollar} alt="" className="img-fluid" />
              <h4 className="mb-3 pt-3">Reasonable Cost</h4>
              <p className="secondary-light-color">
                Providing high-quality services at competitive prices.
              </p>
            </div>
            <div className="col-md-4 approches px-md-5 pe-md-0 me-md-0 me-5">
              <img src={developer} alt="" className="img-fluid" />
              <h4 className="mb-3 pt-3">Seamless Development</h4>
              <p className="secondary-light-color">
                Ensuring a smooth and efficient development process.
              </p>
            </div>
            <div className="col-md-4 approches px-md-5 pe-md-0 ">
              <img src={chat} alt="" className="img-fluid" />
              <h4 className="mb-3 pt-3">Ongoing Support</h4>
              <p className="secondary-light-color">
                Offering continuous assistance and maintenance post-launch.
              </p>
            </div>
          </div>
        </div>
      </div> */}

      <div className="bgforreadytolaunch d-md-block d-none mt-5">
        <div className="container px-5 d-flex align-items-center justify-content-between mx-2 ">
          <div className="mt-5 mx-5">
            <h2
              className="text-start mt-5 mx-4"
              style={{ fontWeight: "600", fontSize: "40px" }}
            >
              Ready to launch your next project?
            </h2>
            <p className="text-start  text-muted mx-4">
              We respect your privacy. No spam, ever! We help you get started
              with your idea.
              <br /> Click on the button to explore what’s in for you
            </p>
          </div>
          <div
            className="col-md-2 btngetfree rounded-2 text-center me-5  p-2 mt-5 sentmsgbtn "
            style={{ cursor: "pointer" }}
            onClick={handleNavigate}
          >
            Get Started
          </div>
        </div>
      </div>

      {windowWidth < 480 && (
        <>
          <div ref={contactSectionRef} className="backgroundforcontactmob ">
            <div className="">
              <div className=" mt-5 p-4">
                <img alt="img" className="img-fluid  " src={imgcontactmob} />
              </div>
              <div className=" mt-2 m3 ">
                <Contactform />
              </div>
            </div>
          </div>
        </>
      )}

      {/* contactform */}
      <Footer />

      <Modal className="border-0 " show={modal}>
        <Modal.Body className="modal-header-bg rounded-2">
          <div className="d-flex flex-wrap justify-content-between mt-3 ">
            <div className="col-2 ">
              <LiaEdit size={35} fill="white" className="mx-3" />
            </div>
            <p className="col-8 text-white text-center  bg-primaryfortext ">
              Please fill out the form below with your enquiry to get in touch
              with us shortly.
            </p>
            <div className="col-2 text-end ">
              <IoMdClose
                size={20}
                className="close-button-popup mt-2 cursor-pointer"
                fill="white"
                onClick={handleCloseModal}
              />
            </div>
          </div>
          <form
            className="contact-form p-md-5 p-2 pt-2 pb-2 mt-5"
            onSubmit={handleSubmit}
          >
            <div className="form-group mb-3">
              <label htmlFor="fullName">Full Name</label>
              <div style={{ height: "50px" }} className="bgformodalcontact">
                <input
                  type="text"
                  className={`form-control inputp  ${
                    errors.fullName ? "is-invalid" : ""
                  }`}
                  id="fullName"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                />
                {errors.fullName && (
                  <div className="invalid-feedback">{errors.fullName}</div>
                )}
              </div>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="email">Email</label>
              <div style={{ height: "50px" }}>
                <input
                  type="email"
                  className={`form-control inputp  ${
                    errors.email ? "is-invalid" : ""
                  }`}
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && (
                  <div className="invalid-feedback">{errors.email}</div>
                )}
              </div>
            </div>

            <div>
              <label htmlFor="contactNumber">Contact Number</label>
              <div className="form-control">
                <PhoneInput
                  placeholder=""
                  international
                  // defaultCountry={country}
                  onChange={handlePhoneChange}
                  value={formData.contactNumber}
                  name="contactNumber"
                  // className="phoneinput"
                  inputProps={{ required: true }}
                  country="US" // in
                  defaultCountry="US"
                  style={{ backgroundColor: "transparent" }}
                  // country={"US"}
                />
                {errors.contactNumber && (
                  <div className="invalid-feedback">{errors.contactNumber}</div>
                )}
              </div>
              {errors.contactNumber && (
                <div className="invalid-feedbackss">{errors.contactNumber}</div>
              )}
            </div>

            <div className="col-lg-7 col-12 mt-4">
              <button
                style={{ backgroundColor: "#2293BB" }}
                type="submit"
                className="p-2 rounded-5 text-white w-100"
              >
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
