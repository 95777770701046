import { Link } from "react-router-dom";
import "./ThankYou.css";
import { useEffect } from "react";
import initializeGTag from "../Analytics";


 export default function ThankYou() {
  //  useEffect(() => {
  //    // First script for async loading of gtag.js
  //    const gtagScript = document.createElement("script");
  //    gtagScript.src =
  //      "https://www.googletagmanager.com/gtag/js?id=AW-16727072441";
  //    gtagScript.async = true;
  //    document.head.appendChild(gtagScript);

  //    // Second script for initializing gtag configuration
  //    const initScript = document.createElement("script");
  //    initScript.innerHTML = `
  //     window.dataLayer = window.dataLayer || [];
  //     function gtag() { dataLayer.push(arguments); }
  //     gtag('js', new Date());
  //     gtag('config', 'AW-16727072441');
  //   `;
  //    document.head.appendChild(initScript);

  //    // Cleanup function to remove scripts on component unmount
  //    return () => {
  //      document.head.removeChild(gtagScript);
  //      document.head.removeChild(initScript);
  //    };
  //  }, []);

   useEffect(() => {
     initializeGTag("G-KC3PT1NEZN"); // Replace with your thank-you page Measurement ID
   }, []);


   return (
     <>
       <div className="thank-you-bg ">
         <div className="container pt-3 p-md-0  divfortext">
           <h1 className="text-center mt-5 p-md-0 p-5
           ">Thank You!</h1>
           <div className="text-center my-md-5">
             <img
               src={require("../images/homeImages/thankyou.png")}
               className="img-fluid col-2"
             />
           </div>
           <h3 className="text-center mt-md-0 mt-3">
             We appreciate your interest in{" "}
             <span style={{ color: "#028D4A" }}>Datart Infotech.</span> <br />A
             member of our team will be in touch with you shortly.
           </h3>
           <h5 className="my-4 text-center">
             Learn more about our expertise by checking out our case studies.
           </h5>

           <div className="text-center mt-5">
             <Link to="/#case-studies">
               <button
                 style={{ backgroundColor: "rgb(34, 147, 187)" }}
                 className="text-decoration-none p-2 px-5 text-white rounded"
               >
                 Read Our Case Studies
               </button>
             </Link>
           </div>
         </div>
       </div>
     </>
   );
 }

// export default ThankYou;
