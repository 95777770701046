export const techItemsArray = [
  {
    img: require("../../images/techImages/webdevelopment.png"),
    title: "Custom Web Applications ",

    flippedTitle: "Custom Web Applications ",
    flippedText:
      "Transform your business with cutting-edge web solutions. We build responsive, high-performance applications using modern frameworks that scale with your needs. Features include real-time updates, offline capabilities, and detailed analytics to drive informed decisions.",
  },
  {
    img: require("../../images/techImages/ecom.png"),
    title: "E-Commerce Platforms",

    flippedTitle: "E-Commerce Platforms",
    flippedText:
      "Launch and grow your online store with our secure, scalable e-commerce solutions. Get integrated payment processing, automated inventory management, and smart product recommendations. Boost sales with optimized checkout flows, mobile-first design, and seamless integration with your existing tech stack.",
  },
  {
    img: require("../../images/techImages/mobapp.png"),
    title: "Mobile Applications ",

    flippedTitle: "Mobile Applications ",
    flippedText:
      "Reach customers everywhere with engaging mobile apps for iOS and Android. Our solutions feature intuitive interfaces, offline functionality, and secure data handling. From concept to deployment, we ensure your app delivers exceptional user experiences through native features, push notifications, and seamless updates.",
  },
];
