const initializeGTag = (measurementId) => {
  if (!window.gtag) {
    // Dynamically load gtag.js
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
    document.head.appendChild(script);

    // Initialize gtag
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    window.gtag = gtag;
    gtag("js", new Date());
  }

  // Configure the tag
  window.gtag("config", measurementId);
};

export default initializeGTag;
