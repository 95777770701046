import PropTypes from "prop-types";
import "./Header.css";

import { Container, Nav, Navbar } from "react-bootstrap";
// import Logo from "../images/logolight.png";
import LogoImg from "../images/Logo2.png";
import Logo from '../images/logoforheader.png'

export default function Header({ handleNavigate }) {
  const path = window.location.pathname;

  return (
    <div className="header">
      <div className="">
        <Navbar expand="lg">
          <Container fluid>
            <Nav.Link href="/" className="brand-logo py-2 navbar-brand d-flex align-items-center">
              <img
                alt=""
                src={LogoImg}
                id="logo"
                width="64px"
                height="64px"
                className="me-md-3 me-2 rounded-3  mx-md-4"
              />
              <img
                id="datart"
                src={Logo}
                alt="Logo"
                // className="img-fluid w-100"
                width="183px"
                height="62px"
               
              />
            </Nav.Link>
            <Navbar />
            <Navbar id="navbar-nav">
              <Nav className="justify-content-md-end flex-grow-1 pe-lg-4">
                <Nav.Link
                  id="Contact"
                  onClick={handleNavigate}
                  className={`${
                    path === "/" ? "active" : ""
                  } nav-link cursor-pointer me-lg-3`}
                >
                  Contact Us
                </Nav.Link>
              </Nav>
            </Navbar>
          </Container>
        </Navbar>
      </div>
    </div>
  );
}

Header.propTypes = {
  handleClick: PropTypes.func.isRequired,
};
