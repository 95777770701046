import React from 'react'
import './Testmonial.css'
import img1 from '../../images/case1.png'
import img2 from '../../images/case2.png'
import img3 from '../../images/case3.png'
import { useState } from 'react';

function Testmonials({ handlecase }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpanded1, setIsExpanded1] = useState(false);
  const [isExpanded2, setIsExpanded2] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };
  const handleToggle1 = () => {
    setIsExpanded1(!isExpanded1);
  };
  const handleToggle2 = () => {
    setIsExpanded2(!isExpanded2);
  };

  return (
    <div>
      <div className=" mt-5">
        <div className="bgfordivcase1 rounded-3 p-3">
          <div className="d-flex flex-wrap justify-content-evenly align-items-center">
            <div className="col-md-4 col-12">
              <img src={img1} className="img-fluid" alt="img" />
            </div>
            <div className="col-md-7 col-12">
              <div className="d-md-flex mt-3 d-md-block d-none ">
                <h5 className="me-2">SS Services </h5>|{" "}
                <span className="mx-2">Erosion Monitoring Web App</span>
              </div>
              <div className="mt-3 d-md-none d-block ">
                <h5 className="me-md-2 col-5 p-0 m-0">SS Services </h5>
                <span className="mt-2">Erosion Monitoring Web App</span>
              </div>
              <div>
                <p className="mt-md-0 mt-2">
                  We have engineered a state-of-the-art IoT device and
                  accompanying web application to revolutionize erosion
                  monitoring. This cutting-edge solution is designed to provide
                  real-time data and advanced analytics, ensuring precise
                  monitoring and effective management of erosion in various
                  environments. Our custom-built IoT device is equipped with
                  high-precision sensors that continuously
                  {!isExpanded && "..."}
                  {isExpanded && (
                    <>
                      {" "}
                      monitor and record critical data, including running hours
                      and erosion percentage. <br />
                      The integrated web application serves as the central hub
                      for data visualisation and analysis. It displays
                      comprehensive metrics and analytics derived from the IoT
                      device, offering users a detailed overview of erosion
                      trends. The platform's intuitive interface enables users
                      to access critical insights and calculations with ease,
                      empowering them to make informed decisions to mitigate
                      risks and extend the lifespan of their infrastructure.
                    </>
                  )}
                </p>
                <span
                  onClick={handleToggle}
                  style={{ color: "#4B5563", cursor: "pointer" }}
                >
                  {isExpanded ? "Read less" : "Read more..."}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="d-md-flex gap-md-2 d-md-block d-none ">
          <div className="bgfordivcase2 rounded-3 d-flex align-items-center flex-wrap mt-4 col-md-6 col-12  p-3">
            <div className="col-md-4 col-12">
              <img className="img-fluid " src={img2} alt="img" />
            </div>
            <div className="col-md-8 col-12">
              <div className="d-flex mt-3 mx-3">
                <h5 className="me-2">IZE </h5> |{" "}
                <span className="mx-2">Print on Demand E-Commerce</span>
              </div>
              <div className="mx-3">
                <p>
                  At IZE, we developed a cutting-edge Print-on-Demand e-commerce
                  platform that empowers artists and designers to showcase their
                  creations in a lifelike 3D environment. The platform offers
                  distinct features for both artists and customers, ensuring an
                  efficient and engaging experience
                  {!isExpanded1 && "..."}
                  {isExpanded1 && (
                    <>
                      {" "}
                      while navigating the site. With its intuitive interface
                      and advanced visualization tools, IZÉ makes it easy for
                      users to explore, customize, and purchase unique products
                      directly from their favorite creators.
                    </>
                  )}
                </p>
                <span
                  onClick={handleToggle1}
                  className=""
                  style={{ color: "#4B5563", cursor: "pointer" }}
                >
                  {isExpanded1 ? "Read less" : "Read more..."}
                </span>
              </div>
            </div>
          </div>
          <div className="bgfordivcase3 rounded-3 col-md-6 col-12 d-flex  align-items-center flex-wrap p-3 mt-4 ">
            <div className="col-md-4 col-12">
              <img className="img-fluid" src={img3} alt="img" />
            </div>
            <div className="col-md-8 col-12 ">
              <div className="d-flex  mx-3">
                <h5 className=" me-2"> Start My Venture </h5> | {" "}
                <span className="mx-2 "> Business Consultation</span>
              </div>
              <div className="mx-3">
                <p className="">
                  An innovative online platform was designed for Start My
                  Venture, tailored specifically for aspiring entrepreneurs. The
                  user-friendly interface and essential tools simplify the
                  process of starting and managing businesses.
                  {!isExpanded2 && "..."}
                  {isExpanded2 && (
                    <>
                      {" "}
                      This platform empowers users with valuable resources,
                      enabling them to turn their entrepreneurial dreams into
                      reality while saving time and money.
                    </>
                  )}
                </p>
                <span
                  onClick={handleToggle2}
                  style={{ color: "#4B5563", cursor: "pointer" }}
                >
                  {isExpanded2 ? "Read less" : "Read more..."}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/*  */}

        <div className=" d-md-none">
          <div className="bgfordivcase2 rounded-3 d-flex align-items-center flex-wrap mt-4 col-md-6 col-12  p-3">
            <div className="col-md-4 col-12">
              <img className="img-fluid " src={img2} alt="img" />
            </div>
            <div className="col-md-8 col-12">
              <div className=" mt-3 mx-1">
                <h5 className="p-0 m-0">IZE </h5>
                <span className="mt-2">Print on Demand E-Commerce</span>
              </div>
              <div className="mx-1">
                <p className="mt-md-0 mt-2">
                  At IZE, we developed a cutting-edge Print-on-Demand e-commerce
                  platform that empowers artists and designers to showcase their
                  creations in a lifelike 3D environment. The platform offers
                  distinct features for both artists and customers, ensuring an
                  efficient and engaging experience
                  {!isExpanded1 && "..."}
                  {isExpanded1 && (
                    <>
                      {" "}
                      while navigating the site. With its intuitive interface
                      and advanced visualization tools, IZÉ makes it easy for
                      users to explore, customize, and purchase unique products
                      directly from their favorite creators.
                    </>
                  )}
                </p>
                <span
                  onClick={handleToggle1}
                  className=""
                  style={{ color: "#4B5563", cursor: "pointer" }}
                >
                  {isExpanded1 ? "Read less" : "Read more..."}
                </span>
              </div>
            </div>
          </div>
          <div className="bgfordivcase3 rounded-3 col-md-6 col-12 d-flex  align-items-center flex-wrap p-3 mt-4 ">
            <div className="col-md-4 col-12">
              <img className="img-fluid" src={img3} alt="img" />
            </div>
            <div className="col-md-8 col-12">
              <div className="mx-1">
                <h5 className="p-0 m-0"> Start My Venture </h5>{" "}
                <span className="">Business Consultation</span>
              </div>
              <div className="mx-1 ">
                <p className="mt-md-0 mt-2">
                  An innovative online platform was designed for Start My
                  Venture, tailored specifically for aspiring entrepreneurs. The
                  user-friendly interface and essential tools simplify the
                  process of starting and managing businesses.
                  {!isExpanded2 && "..."}
                  {isExpanded2 && (
                    <>
                      {" "}
                      This platform empowers users with valuable resources,
                      enabling them to turn their entrepreneurial dreams into
                      reality while saving time and money. Visit website
                    </>
                  )}
                </p>
                <span
                  onClick={handleToggle2}
                  style={{ color: "#4B5563", cursor: "pointer" }}
                >
                  {isExpanded2 ? "Read less" : "Read more..."}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testmonials