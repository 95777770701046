import React, { useState } from "react";
import { toast } from "react-toastify";
// import "./ContactForm.css";
import './Contactform.css'
import { IoIosRefresh } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PhoneInput from "react-phone-number-input";
import { useEffect } from "react";

// import ReCAPTCHA from "react-google-recaptcha";

export default function Contactform() {
  // Captcha
  const navigate = useNavigate();
  const [captcha, setCaptcha] = useState(Math.random().toString(36).slice(6));
  const [inputText, setInputText] = useState("");
  const [captchaValid, setCaptchaValid] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    contactNumber: "",
    message: "",
  });

  const refreshCaptcha = () => setCaptcha(Math.random().toString(36).slice(6));

const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData({ ...formData, [name]: value });

  if (name === "fullName") {
    const trimmedValue = value.trim();
    setErrors((prevErrors) => ({
      ...prevErrors,
      fullName:
        trimmedValue.length < 3 ? "Name must be at least 3 characters." : "",
    }));
  } else if (name === "email") {
    const emailPattern = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
   setErrors((prevErrors) => ({
     ...prevErrors,
     email: !emailPattern.test(value) ? "Email is required." : "",
   }));
  }
};

 const handleChangePhone = (value) => {
   setPhoneNumber(value);
   const phoneNumberPattern = /^\+?[1-9]\d{1,14}$/;
   setFormData({ ...formData, contactNumber: value }); // Update formData with the phone number
   setErrors({
     ...errors,
     contactNumber: !phoneNumberPattern.test(value)
       ? "Contact Number is required."
       : "",
   });
 };

  
  const validateForm = () => {
    const errors = {};
    let isValid = true;

   if (!formData.fullName.trim()) {
     errors.fullName = "Full Name is required";
     isValid = false;
   } else if (formData.fullName.trim().length < 3) {
     errors.fullName = "Name must be at least 3 characters.";
     isValid = false;
   }

    if (!formData.email.trim()) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is invalid";
      isValid = false;
    }

    if (!formData.contactNumber.trim()) {
      errors.contactNumber = "Contact Number is required";
      isValid = false;
    // } else if (!/^\d{10}$/.test(formData.contactNumber)) {
    //   errors.contactNumber = "Contact Number is invalid";
    //   isValid = false;
    }
    // if (!formData.message.trim()) {
    //   errors.message = "Message is required";
    //   isValid = false;
    // }
    setErrors(errors);

    return isValid;
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      // toast.error("Invalid Input! Please provide valid information.");
      return;
    }

    // If validation passes, proceed with form submission
    const data = {
      fullName: formData.fullName,
      email: formData.email,
      contactNumber: phoneNumber,
      message: formData.message || "No message provided.",
    };

    try {
      const response = await axios.post(
        "https://server-dot-apenterpirse-test.el.r.appspot.com/sendcontactmail",
        data
      );
      if (response.status === 200) {

        toast.success(
          "Thank you for reaching out to us. We'll get back to you shortly."
        );

        console.log("sucessfull contactmsg", data);
        setFormData({
          fullName: "",
          email: "",
          contactNumber: "",
          message: "",
        });
        setPhoneNumber("");
        setInputText("");
        setCaptchaValid(false);
        setCaptcha(Math.random().toString(36).slice(6));
        navigate("/thank-you");
      }
      // alert("doneee");
      //   navigate("/thank-you");
    } catch (error) {
      toast.error("Oops! Something went wrong.");
      console.error("Error:", error);
    }
  };

  console.log("formadata", formData);
   


  // const handleThankuPage = () => {
  //   navigate("/thank-you");
  // };


  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); 
  
  

  return (
    <form
      onSubmit={handleSubmit}
      id="contactForm"
      className="p-lg-4 px-md-5  py-md-0 mx-md-0 mx-4"
    >
      <h1 className="text-white">Connect With Us</h1>
      <p className="text-white">
        Contact us to unlock endless possibilities for your business growth and
        witness our commitment to your success firsthand.
      </p>
      <div className="d-md-block d-none">
        <div className="w-75  forinputfocus">
          <input
            type="text"
            className="w-100  py-2 px-2 rounded-3 mb-1 mt-2  bginputform border "
            placeholder="Your Name"
            name="fullName"
            value={
              formData?.fullName
                ? formData?.fullName?.charAt(0)?.toUpperCase() +
                  formData?.fullName?.slice(1)
                : ""
            }
            // value={formData.fullName}
            onChange={handleChange}
          />
          {errors.fullName && (
            <small className="text-danger">{errors.fullName}</small>
          )}
        </div>
        <div className="w-75 mt-3 forinputfocus">
          <input
            type="text"
            className="w-100 py-2 px-2 rounded-3 mb-1 bginputform border "
            placeholder="Your Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
          {errors.email && (
            <small className="text-danger">{errors.email}</small>
          )}
        </div>
        <div className="form-control w-75 bginputform mt-3">
          <PhoneInput
            // country="IN"
            country="US" // in
            defaultCountry="US"
            name="contactNumber"
            placeholder="Mobile Number"
            value={phoneNumber}
            onChange={handleChangePhone}
            inputProps={{ required: true }}
            className="custom-phone-input "
            style={{ backgroundColor: "transparent" }}
          />
        </div>
        {errors.contactNumber && (
          <small className="text-danger">{errors.contactNumber}</small>
        )}
        <div className="w-75 mt-3 forinputfocus ">
          <textarea
            rows="4"
            className="w-100 py-2 px-2 rounded-3 mb-2  bginputform border"
            placeholder="Type your message here (optional)"
            name="message"
            // value={formData.message}
            value={
              formData?.message
                ? formData?.message?.charAt(0)?.toUpperCase() +
                  formData?.message?.slice(1)
                : ""
            }
            onChange={handleChange}
          />
          {errors.message && (
            <small className="text-danger" style={{ visibility: "hidden" }}>
              {errors.message}
            </small>
          )}
        </div>
        <button
          type="submit"
          className="btn  border  w-50 text-white px-md-5 rounded-3 py-2   sentmsgbtn "
        >
          Send Message
        </button>
      </div>

      {windowWidth < 480 && (
        <>
          <div className="w-100  forinputfocus">
            <input
              type="text"
              className="w-100  py-2 px-2 rounded-3 mb-1 mt-2  bginputform border "
              placeholder="Your Name"
              name="fullName"
              // value={formData.fullName}
              value={
                formData?.fullName
                  ? formData?.fullName?.charAt(0)?.toUpperCase() +
                    formData?.fullName?.slice(1)
                  : ""
              }
              onChange={handleChange}
            />
            {errors.fullName ? (
              <small className="error text-danger">{errors.fullName}</small>
            ) : (
              <div className="pb-3"></div>
            )}
          </div>
          <div className="w-100 mt-3 forinputfocus">
            <input
              type="text"
              className="w-100 py-2 px-2 rounded-3 mb-1 bginputform border "
              placeholder="Your Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email ? (
              <small className="error text-danger">{errors.email}</small>
            ) : (
              <div className="pb-3"></div>
            )}
          </div>
          <div className="form-control w-100 bginputform mt-3">
            <PhoneInput
              // country="IN"
              country="US" // in
              defaultCountry="US"
              name="contactNumber"
              placeholder="Mobile Number"
              value={phoneNumber}
              onChange={handleChangePhone}
              inputProps={{ required: true }}
              className="custom-phone-input "
              style={{ backgroundColor: "transparent" }}
            />
          </div>
          {errors.contactNumber ? (
            <small className="error text-danger">{errors.contactNumber}</small>
          ) : (
            <div className="pb-3"></div>
          )}
          <div className="w-100 mt-3 forinputfocus ">
            <textarea
              rows="4"
              className="w-100 py-2 px-2 rounded-3 mb-2  bginputform border"
              placeholder="Type your message here (optional)"
              name="message"
              value={formData.message}
              onChange={handleChange}
            />
            {errors.message && (
              <small className="text-danger" style={{ visibility: "hidden" }}>
                {errors.message}
              </small>
            )}
          </div>
          <button
            type="submit"
            className="btn  border  w-100 text-white mt-2 rounded-3 py-2   sentmsgbtn "
          >
            Send Message
          </button>
        </>
      )}
    </form>
  );
}
