import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Testmonials from "./components/Common/Testmonials";



import Home from "./pages/home";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ThankYou from "./pages/ThankYou";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/thank-you" element={<ThankYou />} />
        {/* <Route path="/testimonials" element={<Testmonials />} /> */}
      </Routes>

      <ToastContainer />
    </BrowserRouter>
  );
}
