import React from 'react'
import { FaArrowTrendUp } from "react-icons/fa6";
import { useEffect, useState } from "react";
import icon from "../../images/homeImages/icon-stats.png";
import CountUp from "react-countup";
import "./Stats.css";
import { useInView } from "react-intersection-observer";
import arrow1 from '../../images/arrowstat.png'
import { HiMiniArrowTrendingUp } from "react-icons/hi2";

function Stats() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); 

  const [startCount, setStartCount] = useState(false);
   const [animationKey, setAnimationKey] = useState(0);
  const { ref, inView } = useInView({
    triggerOnce: true, // Ensures animation triggers only once
    threshold: 0.3, // Triggers when 30% of the component is visible
  });

  // useEffect(() => {
  //   if (inView) {
  //     setStartCount(true); // Start count animation when the section is in view
  //   }
  // }, [inView]);
   const handleMouseEnter = () => {
     if (!startCount) {
       setStartCount(true);
     }
   };

  //  const handleAnimationEnd = () => {
  //    setTimeout(() => setAnimationKey((prev) => prev + 1), 1000); // 1-second delay between restarts
  //  };
  return (
    <div ref={ref} onMouseEnter={handleMouseEnter}>
      <div className="mt-3 statbgimg pb-3 ">
        <div className="py-md-5 mb-md-5 ">
          <div className="">
            <div className="text-center ">
              <img
                src={icon}
                className="img-fluid mt-md-0 mt-4"
                alt="iconimg"
              />
            </div>
            <h2 className="text-center my-3 my-md-4 my-lg-0 p-2" style={{fontSize:"42px"}}>
              Revealing Numbers and Metrics
            </h2>
            <p className="text-center text-secondary px-md-5 mb-4 mb-md-0 pforimac mt-2">
              Our journey of groundbreaking concepts drive progress and redefine
              possibilities!
            </p>
          </div>

          <div className="mt-3 mt-md-4 statsdiv d-md-block d-none">
            <div className="text-center">
              <div className="d-flex justify-content-evenly align-items-center col-md-12  flex-wrap px-5">
                <div className="">
                  <h1
                    className="stats-color fornumberimac fontsizestats mx-md-0 mx-4 "
                    style={{ fontSize: "60px" }}
                  >
                    {startCount ? (
                      <CountUp end={27} suffix="+" duration={4} />
                    ) : (
                      0
                    )}
                  </h1>
                  <p className="fortextimac">Delivered Projects</p>
                </div>

                {/* <img src={arrow1} className="img-fluid " alt="img" /> */}

                <div className="vr mx-md-4"></div>

                <div className="">
                  <h1
                    className="stats-color fornumberimac fontsizestats "
                    style={{ fontSize: "60px" }}
                  >
                    {startCount ? (
                      <CountUp end={5} suffix="+" duration={4} />
                    ) : (
                      0
                    )}
                  </h1>
                  <p className="fortextimac">Ongoing Projects</p>
                </div>

                <div className="vr mx-md-4"></div>

                <div className="">
                  <h1
                    className="stats-color fornumberimac fontsizestats "
                    style={{ fontSize: "60px" }}
                  >
                    {startCount ? (
                      <CountUp end={23} suffix="+" duration={4} />
                    ) : (
                      0
                    )}
                  </h1>
                  <p className="fortextimac">Happy Clients</p>
                </div>

                <div className="vr mx-md-4"></div>

                <div className="">
                  <h1
                    className="stats-color fornumberimac fontsizestats "
                    style={{ fontSize: "60px" }}
                  >
                    {startCount ? (
                      <CountUp end={20} suffix="+" duration={4} />
                    ) : (
                      0
                    )}
                  </h1>
                  <p className="fortextimac">Team Size</p>
                </div>
              </div>
            </div>
          </div>

          <div className=" mt-3 mt-md-4  statsdiv d-md-none d-block ">
            <div className="text-center">
              <div className="d-flex justify-content-center align-items-center col-md-12  gap-md-5 flex-wrap">
                <div className="col-6">
                  <div>
                    <div className="">
                      <h1 className="stats-color fornumberimac fontsizestats mx-md-0 mx-4">
                        {/* {startCount ? (
                          <CountUp end={27} suffix="+" duration={4} />
                        ) : (
                          0
                        )} */}
                        27+
                      </h1>
                    </div>
                    <p className="fortextimac">Delivered Projects</p>
                  </div>

                  <div className="mt-3">
                    <div className="d-flex flex-wrap justify-content-around">
                      <h1 className="stats-color fornumberimac fontsizestats">
                        {/* {startCount ? (
                          <CountUp end={5} suffix="+" duration={4} />
                        ) : (
                          0
                        )} */}
                        5+
                      </h1>
                    </div>
                    <p className="fortextimac">Ongoing Projects</p>
                  </div>
                </div>
                <div className="col-6 ">
                  <div className="">
                    <div className="d-flex flex-wrap justify-content-around ">
                      <h1 className="stats-color fornumberimac fontsizestats">
                        {/* {startCount ? (
                          <CountUp end={23} suffix="+" duration={4} />
                        ) : (
                          0
                        )} */}
                        23+
                      </h1>
                    </div>
                    <p className="fortextimac">Happy Clients</p>
                  </div>

                  <div className="mt-3">
                    <div className="d-flex flex-wrap justify-content-around">
                      <h1 className="stats-color fornumberimac fontsizestats">
                        {/* {startCount ? (
                          <CountUp end={15} suffix="+" duration={4} />
                        ) : (
                          0
                        )} */}
                        20+
                      </h1>
                    </div>
                    <p className="fortextimac">Team Size</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Stats